import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Button, { LinkButton } from "../components/Button";

import { HTMLContent } from "../components/Content";
import { Helmet } from "react-helmet";
import logoSolidWhite from "../img/signature-multi-white.svg";

const LoginButton = ({ title, href, description, btnColorClass }) => (
  <div className="my-2 sm:my-4 md:my-0 w-full md:w-4/12">
    <Button
      href={href}
      className={`${btnColorClass} px-8 w-10/12 sm:w-1/2 md:w-auto lg:text-lg`}
    >
      {title}
    </Button>
    <p className="text-lg mt-4 md:mt-8 md:px-4">{description}</p>
  </div>
);

const LoginPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout
      headerClassName="bg-accent"
      headerContent={<span />}
      navbarLogo={logoSolidWhite}
      className="accent-navy"
      headerTitle="Not found"
    >
      <Helmet titleTemplate="%s - ScriptDrop">
        <title>{`${post.frontmatter.title}`}</title>
      </Helmet>

      <section className="container mt-8 mb-8 lg:mt-12">
        <h1 className="font-heading text-center text-3xl lg:text-4xl xl:text-5xl mb-6 md:mb-12 lg:mb-16">
          {post.frontmatter.heading}
        </h1>

        <HTMLContent
          className="mt-8 mb-8 lg:mb-12 text-center prose lg:prose-lg max-w-none"
          content={post.html}
        />

        <div className="columns md:divide-y-0 md:divide-x divide-navy-p2 text-center">
          <LoginButton
            href="https://pharmacy-admin.scriptdrop.co/"
            title="Pharmacy Portal"
            description="For pharmacists, staff, and administrators"
          />

          <LoginButton
            href="https://portal.scriptdrop.co/"
            title="Driver App"
            description="For couriers"
            btnColorClass="btn-goldenrod"
          />

          <LoginButton
            title="Courier Admin"
            href="https://courier-admin.scriptdrop.co/"
            description="Dashboard for courier partner dispatchers and administrators"
            btnColorClass="btn-sky"
          />
        </div>
      </section>

      <section className="container mt-8 mb-8 lg:mt-12">
        <div class="columns">
          <div class="w-full md:w-10/12 text-center">
            <h2 className="font-heading text-violet text-2xl mb-6 lg:text-3xl">
              Patients
            </h2>
            <LinkButton to="/patients" className="btn-violet-o px-8">
              Learn more about prescription&nbsp;delivery
            </LinkButton>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default LoginPage;

export const pageQuery = graphql`
  query loginPageQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        heading
      }
    }
  }
`;
