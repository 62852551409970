import React from "react";
import { Link } from "gatsby";

export const Button = ({ href, title, className, children }) => (
  <a
    className={`btn btn-avenir-semibold ${className}`}
    href={href}
    title={title}
  >
    {children}
  </a>
);

export const LinkButton = ({ to, title, className, children }) => (
  <Link
    className={`btn btn-avenir-semibold ${className}`}
    to={to}
    title={title}
  >
    {children}
  </Link>
);

export default Button;
